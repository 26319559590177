import React from "react";
import { withPrefix, graphql } from "gatsby";

// Layouts
import Default from '../../layouts/default';
import Seo from '../../layouts/seo';
import SalaryCalculator from '../../layouts/salary-calculator';

// Components
import Content, { HTMLContent } from "../../components/content-renderer";
import PageHeader from '../../components/page-header';

const Salary = ({ data }) => {
  const pageInfo = data.toolInfo;
  const definitions = data.definitions.nodes;
  const PageContent = HTMLContent || Content;
  const headerImage = pageInfo.frontmatter.image.relativePath;

  return (
    <Default className='centre-content'>
      <Seo
        title={pageInfo.frontmatter.title}
        description={pageInfo.frontmatter.description}
      />

      <PageHeader
        heading={pageInfo.frontmatter.title}
        imageSource={`${withPrefix("/")}img/${headerImage}`}
        modalContent={(
            <PageContent content={pageInfo.html} />
        )}
        description={pageInfo.frontmatter.description}
        breadcrumbs={[
            {name: 'Home', url: '/'},
            {name: 'Personal finance', url: '/personal-finance'},
        ]}
      />

      <SalaryCalculator
        definitions={definitions}
      />
    </Default>
  );
};

export default Salary;

export const TakeHomePayQuery = graphql`
  query TakeHomePay($id: String!) {
    toolInfo: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        image {
            relativePath
        }
        description
      }
    }
    definitions: allMarkdownRemark(
      filter: {fileAbsolutePath: {
          regex: "/src/definitions/salary|src/definitions/bonus-pay|/src/definitions/student-loan-plans|/src/definitions/employee-pension-contribution|/src/definitions/no-national-insurance-ni|/src/definitions/blindness-allowance|/src/definitions/married-couples-allowance|/src/definitions/self-employment|/src/definitions/gross-income/"
      }},
      sort: {order: ASC, fields: frontmatter___title}
    ) {
      nodes {
          html
          frontmatter {
              title
          }
      }
    }
  }
`;