/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Helpers
import { formatNumber } from '../../helpers/helpers';

// Component imports
import Table from '../../components/table';
import InfoModal from '../../components/info-modal';
import Content, { HTMLContent } from "../../components/content-renderer";

const Results = ({
    definitions,
    bonusPay,
    grossIncome,
    personalAllowance,
    taxableIncome,
    incomeTax,
    nationalInsurance,
    studentLoan,
    pension,
    mcaTaxRebate,
    takeHomePay,
}) => {
    const PageContent = HTMLContent || Content;

    // React Redux hooks
    const query = useSelector(state => state.salary);

    const numberDays = query.workingDays === '' || isNaN(query.workingDays) ? 1 : parseFloat(query.workingDays);
    const numberHours = query.workingHours === '' || isNaN(query.workingHours) ? 1 : parseFloat(query.workingHours);
    
    // Calculate yearly amount
	const calcYearlyAmount = (val) => {
        return formatNumber(val);
	};

	// Calculate monthly amount
	const calcMonthlyAmount = (val) => {
        return formatNumber(val / 12);
    };

	// Calculate weekly amount
	const calcWeeklyAmount = (val) => {
        return formatNumber(val / 52);
	};

	// Calculate daily amount
	const calcDailyAmount = (val) => {
        return formatNumber(val / 52 / numberDays);
	};

	// Calculate hourly amount
	const calcHourlyAmount = (val) => {
        return formatNumber(val / 52 / numberDays / numberHours);
    };

    return (
        <Table variant='fixed-column'>
            <Table.Header>
                <th></th>
                <th>Year</th>
                <th>Month</th>
                <th>Week</th>
                <th>Day</th>
                <th>Hour</th>
            </Table.Header>
            <tbody>
                <tr>
                    <td>
                        <InfoModal
                            triggerText='Gross Income'
                            heading={definitions[3].frontmatter.title}
                        >
                            <PageContent content={definitions[3].html} />
                        </InfoModal>
                    </td>
                    <td>{calcYearlyAmount(grossIncome)}</td>
                    <td>{calcMonthlyAmount(grossIncome)}</td>
                    <td>{calcWeeklyAmount(grossIncome)}</td>
                    <td>{calcDailyAmount(grossIncome)}</td>
                    <td>{calcHourlyAmount(grossIncome)}</td>
                </tr>
                <tr>
                    <td>Personal Allowance</td>
                    <td>{calcYearlyAmount(personalAllowance)}</td>
                    <td>{calcMonthlyAmount(personalAllowance)}</td>
                    <td>{calcWeeklyAmount(personalAllowance)}</td>
                    <td>{calcDailyAmount(personalAllowance)}</td>
                    <td>{calcHourlyAmount(personalAllowance)}</td>
                </tr>
                {query.pensionContribution >= 1 && (
                    <tr>
                        <td>Pension Deductions</td>
                        <td>{calcYearlyAmount(pension)}</td>
                        <td>{calcMonthlyAmount(pension)}</td>
                        <td>{calcWeeklyAmount(pension)}</td>
                        <td>{calcDailyAmount(pension)}</td>
                        <td>{calcHourlyAmount(pension)}</td>
                    </tr>
                )}
                <tr>
                    <td>Taxable Income</td>
                    <td>{calcYearlyAmount(taxableIncome)}</td>
                    <td>{calcMonthlyAmount(taxableIncome)}</td>
                    <td>{calcWeeklyAmount(taxableIncome)}</td>
                    <td>{calcDailyAmount(taxableIncome)}</td>
                    <td>{calcHourlyAmount(taxableIncome)}</td>
                </tr>
                <tr>
                    <td>Income Tax</td>
                    <td>{calcYearlyAmount(incomeTax)}</td>
                    <td>{calcMonthlyAmount(incomeTax)}</td>
                    <td>{calcWeeklyAmount(incomeTax)}</td>
                    <td>{calcDailyAmount(incomeTax)}</td>
                    <td>{calcHourlyAmount(incomeTax)}</td>
                </tr>
                <tr>
                    <td>National Insurance</td>
                    <td>{calcYearlyAmount(nationalInsurance)}</td>
                    <td>{calcMonthlyAmount(nationalInsurance)}</td>
                    <td>{calcWeeklyAmount(nationalInsurance)}</td>
                    <td>{calcDailyAmount(nationalInsurance)}</td>
                    <td>{calcHourlyAmount(nationalInsurance)}</td>
                </tr>
                {query.studentLoanPlan !== 'None' && (
                    <tr>
                        <td>Student Loan</td>
                        <td>{calcYearlyAmount(studentLoan)}</td>
                        <td>{calcMonthlyAmount(studentLoan)}</td>
                        <td>{calcWeeklyAmount(studentLoan)}</td>
                        <td>{calcDailyAmount(studentLoan)}</td>
                        <td>{calcHourlyAmount(studentLoan)}</td>
                    </tr>
                )}
                {query.bonus && (
                    <tr>
                        <td>Bonus{query.bonusAsPercentage ? ` (${query.bonus}%)` : ''}</td>
                        <td>{calcYearlyAmount(bonusPay)}</td>
                        <td>{calcMonthlyAmount(bonusPay)}</td>
                        <td>{calcWeeklyAmount(bonusPay)}</td>
                        <td>{calcDailyAmount(bonusPay)}</td>
                        <td>{calcHourlyAmount(bonusPay)}</td>
                    </tr>
                )}
                {query.mca === true && (
                    <tr>
                        <td>MCA</td>
                        <td>{calcYearlyAmount(mcaTaxRebate)}</td>
                        <td>{calcMonthlyAmount(mcaTaxRebate)}</td>
                        <td>{calcWeeklyAmount(mcaTaxRebate)}</td>
                        <td>{calcDailyAmount(mcaTaxRebate)}</td>
                        <td>{calcHourlyAmount(mcaTaxRebate)}</td>
                    </tr>
                )}
            </tbody>
            <Table.Footer>
                <tr>
                    <td>Take Home Pay</td>
                    <td>{calcYearlyAmount(takeHomePay)}</td>
                    <td>{calcMonthlyAmount(takeHomePay)}</td>
                    <td>{calcWeeklyAmount(takeHomePay)}</td>
                    <td>{calcDailyAmount(takeHomePay)}</td>
                    <td>{calcHourlyAmount(takeHomePay)}</td>
                </tr>
            </Table.Footer>
        </Table>
    );
};

Results.propTypes = {
    definitions: PropTypes.array.isRequired,
    bonusPay: PropTypes.number.isRequired,
    grossIncome: PropTypes.number.isRequired,
    personalAllowance: PropTypes.any.isRequired,
    taxableIncome: PropTypes.any.isRequired,
    incomeTax: PropTypes.any.isRequired,
    nationalInsurance: PropTypes.any.isRequired,
    studentLoan: PropTypes.number.isRequired,
    pension: PropTypes.number.isRequired,
    mcaTaxRebate: PropTypes.number.isRequired,
    takeHomePay: PropTypes.number.isRequired,
};

export default Results;