/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Component Imports
import Section from '../../components/section';
import Form from '../../components/form';
import InputSwitch from '../../components/input-switch';
import Select from '../../components/select';
import SummaryCard from '../../components/summary-card';
import InputField from '../../components/input-field';
import ShareResults from '../../components/share-results';
import Content, { HTMLContent } from "../../components/content-renderer";

// Helpers
import withShareResults from '../../helpers/with-share-results';
import { checkNull } from '../../helpers/check-null';

// Partial Imports
import Results from './results';

const SalaryCalculator = ({
    definitions,
}) => {
    const { getShareLink, selectedYearParam } = withShareResults();
    const PageContent = HTMLContent || Content;

	// React Redux hooks
	const dispatch = useDispatch();
    const figures = useSelector(state => state.yearlyFigures);
    const query = useSelector(state => state.salary);

    useEffect(() => {
        return () => {
            dispatch({ type: 'RESET_YEAR' });
            dispatch({ type: 'RESET_TAKE_HOME_SELECTIONS' });
        };
    }, []);
    
    //  Applies url params on load
    useEffect(() => {
        if (selectedYearParam) {
            dispatch({ type: 'SET_YEAR', payload: selectedYearParam });
        }
 
        dispatch({ type: 'APPLY_TAKE_HOME_URL_PARAMS' });
    }, [selectedYearParam]);
	
	// Bonus pay as a fixed amount or as a percentage of salary
	const bonusPay = query.bonusAsPercentage ? checkNull(query.salary) * (checkNull(query.bonus) / 100) : checkNull(query.bonus);
	
    const grossIncome = checkNull(query.salary) + checkNull(bonusPay);
    
	// Calculate salary based on 'Paid per' period input
	const calcGrossIncome = () => {
        const numberDays = query.workingDays === '' || isNaN(query.workingDays) ? 1 : parseFloat(query.workingDays);
        const numberHours = query.workingHours === '' || isNaN(query.workingHours) ? 1 : parseFloat(query.workingHours);
        
        return (query.paidPer === 'year') ? grossIncome :
            (query.paidPer === 'month') ? grossIncome * 12 :
            (query.paidPer === 'fortnight') ? grossIncome * 26 :
            (query.paidPer === 'week') ? grossIncome * 52 :
            (query.paidPer === 'day') ? grossIncome * numberDays * 52 :
            (query.paidPer === 'hour') ? grossIncome * numberHours * numberDays * 52 :
            null;
    };

    // Pension calculation
	const calcPension = () => {
        return calcGrossIncome() * (checkNull(query.pensionContribution) / 100);
    };

    const adjustedNetIncome = calcGrossIncome() - calcPension();

	// Personal Allowance Reduction
	// Personal Allowance goes down by £1 for every £2 that adjusted net income (gross pay - pension) is above 100,000 (https://www.gov.uk/income-tax-rates/income-over-100000)
	const personalAllowanceReduction = adjustedNetIncome > figures.personalAllowanceReducerAllowance ? (adjustedNetIncome - figures.personalAllowanceReducerAllowance) / 2 : 0;

	// Blindness Allowance is applied apply new figure (https://www.gov.uk/blind-persons-allowance/what-youll-get)
	const checkBlindnessAllowance = query.blind ? figures.personalAllowance + figures.blindAllowance : figures.personalAllowance;

	// Calculate corrected Personal Allowance with reduction if gross wage > 100,000
	const calcPersonalAllowance = checkBlindnessAllowance - personalAllowanceReduction;
	const calcCorrectedPersonalAllowance = calcPersonalAllowance < 0 ? '0.00' : calcPersonalAllowance;

	// Taxable Income calculation
	const calcTaxableIncome = calcGrossIncome() - calcCorrectedPersonalAllowance - calcPension();
	const calcCorrectedTaxableIncome = calcTaxableIncome < 0 ? '0.00' : calcTaxableIncome;
	
	// MCA (Married Couples Allowance) calculation
	// https://www.citizensadvice.org.uk/debt-and-money/tax/income-tax-how-much-should-you-pay/income-tax-allowances-and-amounts/
	// Deducts 10% of eligible MCA from Income Tax
	// Gross income below income threshold: (max allowance)
	// Gross income above income threshold: falls £1 for every £2 over minimum threshold to minimum (min allowance)
	const calcMCATaxRebate = calcGrossIncome() < figures.mcaIncomeThreshold ? figures.mcaMaxAllowance * 0.1 :
		calcGrossIncome() >= figures.mcaIncomeThreshold ? (figures.mcaMaxAllowance - ((calcGrossIncome() - figures.mcaIncomeThreshold) / 2)) * 0.1 :
		null;

	const calcCorrectedMCATaxRebate = !query.mca ? 0 :
		calcMCATaxRebate < figures.mcaMinAllowance ? figures.mcaMinAllowance :
        calcMCATaxRebate;
	
	// Income Tax calculation
	// https://www.gov.uk/income-tax-rates
	// 0% up to Personal Allowance
	// Basic Rate Tax between Personal Allowance and Basic Rate Band
	// Higher Rate Tax between Basic Rate Band and Higher Rate Band
	// Additional Rate Tax for earnings above  Higher Rate Band
    const personalAllowanceRationalised = calcPersonalAllowance < 0 ? 0 : calcPersonalAllowance;
    const basicRate = personalAllowanceRationalised + (figures.basicRateBand - figures.personalAllowance);

    const calcIncomeTax = () => {
        let result = 0;

        if (adjustedNetIncome <= personalAllowanceRationalised) {
            result = '0.00';
        }
        else if (adjustedNetIncome > personalAllowanceRationalised && adjustedNetIncome <= basicRate) {
            result = ((adjustedNetIncome - personalAllowanceRationalised) * figures.basicRateTax) - calcCorrectedMCATaxRebate;
        }
        else if (adjustedNetIncome > basicRate && adjustedNetIncome <= figures.higherRateBand) {
            result = ((basicRate - personalAllowanceRationalised) * figures.basicRateTax) + ((adjustedNetIncome - basicRate) * figures.higherRateTax) - calcCorrectedMCATaxRebate;
        }
        else if (adjustedNetIncome > figures.higherRateBand) {
            result = ((basicRate - personalAllowanceRationalised) * figures.basicRateTax) + ((figures.higherRateBand - basicRate) * figures.higherRateTax) + ((adjustedNetIncome - figures.higherRateBand) * figures.additionalRateTax) - calcCorrectedMCATaxRebate;
        }
        
        return result;
    }

	const calcCorrectedIncomeTax = (calcIncomeTax() < 0) ? '0.00' : calcIncomeTax();

    const calcNationalInsurance = () => {
        // National Insurance calculation
        // https://www.which.co.uk/money/tax/national-insurance/national-insurance-rates-ajg9u9p48f2f
        let result = 0;
            
        // Class 1 rate (Employed)
        // Less than Threshold: 0%
        // Threshold-Higher Income Band: Basic Rate (%)
        // For earnings above Higher Income Band: Higher Rate (%)
        if (!query.selfEmployed) {
            result = calcGrossIncome() < figures.niThreshold || query.noNI ? '0.00' :
            calcGrossIncome() >= figures.niThreshold && calcGrossIncome() <= figures.niHigherBand ? (calcGrossIncome() - figures.niThreshold - 1) * figures.niBasicRate :
            calcGrossIncome() > figures.niHigherBand ? ((figures.niHigherBand - figures.niThreshold - 1) * figures.niBasicRate) + ((calcGrossIncome() - figures.niHigherBand) * figures.niHigherRate) :
            null;
        }
        // Class 2 and 4 rates (Self-employed)
        // Less than Class 2 Threshold: 0%
        // Threshold+ : Lower Rate (£) per week
        // Lower Income Band - Higher Income Band: Basic Rate (%)
        // For earnings above Higher Income Band: Higher Rate (%)
        else {
            result = (calcGrossIncome() < figures.niClass2Threshold) ? '0.00' :
            calcGrossIncome() >= figures.niClass2Threshold && calcGrossIncome() < figures.niThreshold ? figures.niClass2LowerRate * 52 : 
            calcGrossIncome() >= figures.niThreshold && calcGrossIncome() <= figures.niHigherBand ? ((calcGrossIncome() - figures.niThreshold - 1) * figures.niClass2BasicRate) + (figures.niClass2LowerRate * 52) : 
            calcGrossIncome() > figures.niHigherBand ? ((figures.niHigherBand - figures.niThreshold - 1) * figures.niClass2BasicRate) + ((calcGrossIncome() - figures.niHigherBand) * figures.niClass2HigherRate) + (figures.niClass2LowerRate * 52) :
            null;
        }

        return result;
    }

    // Student Loan calculation
	// https://www.gov.uk/repaying-your-student-loan/what-you-pay
	const calcStudentLoan = () => {
        let result = 0;

        switch(query.studentLoanPlan) {
            case 'Plan1':
                if (calcGrossIncome() >= figures.plan1Threshold) {
                    result = (calcGrossIncome() - figures.plan1Threshold) * figures.plan1RepaymentRate;
                }
				break;
            case 'Plan2':
                if (calcGrossIncome() >= figures.plan2Threshold) {
                    result = (calcGrossIncome() - figures.plan2Threshold) * figures.plan2RepaymentRate;
                }
				break;
            case 'Plan1Plan2':
                if (calcGrossIncome() >= figures.plan1Threshold) {
                    result = (calcGrossIncome() - figures.plan1Threshold) * figures.plan1RepaymentRate;
                }
				break;
            case 'Postgraduate':
                if (calcGrossIncome() >= figures.postgraduateThreshold) {
                    result = (calcGrossIncome() - figures.postgraduateThreshold) * figures.postgraduateRepaymentRate;
                }
				break;
            case 'PostgraduatePlan1':
                if (calcGrossIncome() >= figures.postgraduateThreshold && calcGrossIncome() < figures.plan1Threshold) {
                    result = (calcGrossIncome() - figures.postgraduateThreshold) * figures.postgraduateRepaymentRate;
                }
                else if (calcGrossIncome() >= figures.plan1Threshold && calcGrossIncome() < figures.postgraduateThreshold) {
                    result = (calcGrossIncome() - figures.plan1Threshold) * figures.plan1RepaymentRate;
                }
                else if (calcGrossIncome() >= figures.plan1Threshold && calcGrossIncome() >= figures.postgraduateThreshold) {
                    result = ((calcGrossIncome() - figures.plan1Threshold) * figures.plan1RepaymentRate) + ((calcGrossIncome() - figures.postgraduateThreshold) * figures.postgraduateRepaymentRate);
                }
				break;
            case 'PostgraduatePlan2':
                if (calcGrossIncome() >= figures.postgraduateThreshold && calcGrossIncome() < figures.plan2Threshold) {
                    result = (calcGrossIncome() - figures.postgraduateThreshold) * figures.postgraduateRepaymentRate;
                }
                else if (calcGrossIncome() >= figures.postgraduateThreshold && calcGrossIncome() >= figures.plan2Threshold) {
                    result = ((calcGrossIncome() - figures.postgraduateThreshold) * figures.postgraduateRepaymentRate) + ((calcGrossIncome() - figures.plan2Threshold) * figures.plan2RepaymentRate);
                }
                else if (calcGrossIncome() >= figures.plan2Threshold && calcGrossIncome() < figures.postgraduateThreshold) {
                    result = (calcGrossIncome() - figures.plan2Threshold) * figures.plan2RepaymentRate;
                }
				break;
            case 'Plan4':
                if (calcGrossIncome() >= figures.plan4Threshold) {
                    result = (calcGrossIncome() - figures.plan4Threshold) * figures.plan4RepaymentRate;
                }
                break;
            case 'Plan5':
                if (calcGrossIncome() >= figures.plan5Threshold) {
                    result = (calcGrossIncome() - figures.plan5Threshold) * figures.plan5RepaymentRate;
                }
                break;
            default:
        };

        return result;
    };

    
	// Result
	const takeHomePay = calcGrossIncome() - calcIncomeTax() - calcNationalInsurance() - calcStudentLoan() - calcPension();
    
    return (
        <>
            <Section label='Calculator'>
                <Form>
                    <Form.Fieldset>
                        <Form.Row>
                            <InputField
                                id='salary'
                                labelText='Salary'
                                modalHeading={definitions[6].frontmatter.title}
                                modalContent={<PageContent content={definitions[6].html} />}
                                placeholder='0'
                                symbol='currency'
                                min={0}
                                onChange={(event) => dispatch({
                                    type: 'SET_TAKE_HOME_SALARY',
                                    payload: event.target.value,
                                })}
                                required
                                value={query.salary}
                            />

                            <InputField
                                id='bonus'
                                labelText='Bonus'
                                modalHeading={definitions[1].frontmatter.title}
                                modalContent={<PageContent content={definitions[1].html} />}
                                placeholder='0'
                                symbol={query.bonusAsPercentage ? 'percentage' : 'currency'}
                                min={0}
                                max={query.bonusAsPercentage ? 100 : undefined}
                                onChange={(event) => dispatch({
                                    type: 'SET_TAKE_HOME_BONUS',
                                    payload: event.target.value,
                                })}
                                value={query.bonus}
                                checkboxText='Bonus as %'
                                checkboxOnChange={() => dispatch({
                                    type: 'SET_TAKE_HOME_BONUS_AS_PERCENTAGE',
                                    payload: !query.bonusAsPercentage
                                })}
                                checkboxValue={query.bonusAsPercentage}
                            />

                            <Select
                                labelText="Paid per"
                                id="paid-select"
                                onChange={(event) => dispatch({
                                    type: 'SET_TAKE_HOME_PAID_PER',
                                    payload: event.target.value,
                                })}
                                value={query.paidPer}
                            >
                                <Select.Option value="year">Year</Select.Option>
                                <Select.Option value="month">Month</Select.Option>
                                <Select.Option value="fortnight">Fortnight</Select.Option>
                                <Select.Option value="week">Week</Select.Option>
                                <Select.Option value="day">Day</Select.Option>
                                <Select.Option value="hour">Hour</Select.Option>
                            </Select>
                        </Form.Row>
                        
                        <Form.Row>
                            <Select
                                labelText="Student loan"
                                id="student-loan-select"
                                tooltipHeading={definitions[8].frontmatter.title}
                                tooltip={<PageContent content={definitions[8].html} />}
                                onChange={(event) => dispatch({
                                    type: 'SET_TAKE_HOME_STUDENT_LOAN',
                                    payload: event.target.value,
                                })}
                                value={query.studentLoanPlan}
                            >
                                <Select.Option value="None">None</Select.Option>
                                <Select.Option value="Plan1">Plan 1</Select.Option>
                                <Select.Option value="Plan2">Plan 2</Select.Option>
                                <Select.Option value="Postgraduate">Postgraduate</Select.Option>
                                {parseInt(figures.selectedYear, 10) >= 2021 && (
                                    <Select.Option value="Plan4">Plan 4</Select.Option>
                                )}
                                {parseInt(figures.selectedYear, 10) >= 2023 && (
                                    <Select.Option value="Plan5">Plan 5</Select.Option>
                                )}
                                <Select.Option value="Plan1Plan2">Plan 1 &amp; Plan 2</Select.Option>
                                <Select.Option value="PostgraduatePlan1">Postgraduate &amp; Plan 1</Select.Option>
                                <Select.Option value="PostgraduatePlan2">Postgraduate &amp; Plan 2</Select.Option>
                            </Select>

                            <InputField
                                id='pension'
                                labelText='Pension contribution'
                                modalHeading={definitions[2].frontmatter.title}
                                modalContent={<PageContent content={definitions[2].html} />}
                                placeholder='0'
                                symbol='percentage'
                                min={0}
                                max={100}
                                onChange={(event) => dispatch({
                                    type: 'SET_TAKE_HOME_PENSION',
                                    payload: event.target.value,
                                })}
                                value={query.pensionContribution}
                            />

                            <Select
                                labelText="Tax year"
                                id="tax-year-select"
                                onChange={(event) => dispatch({
                                    type: 'SET_YEAR',
                                    payload: event.target.value,
                                })}
                                value={figures.selectedYear}
                            >
                                <Select.Option value='2024'>2024/25</Select.Option>
                                <Select.Option value='2023'>2023/24</Select.Option>
                                <Select.Option value='2022'>2022/23</Select.Option>
                                <Select.Option value='2021'>2021/22</Select.Option>
                                <Select.Option value='2020'>2020/21</Select.Option>
                                <Select.Option value='2019'>2019/20</Select.Option>
                                <Select.Option value='2018'>2018/19</Select.Option>
                                <Select.Option value='2017'>2017/18</Select.Option>
                                <Select.Option value='2016'>2016/17</Select.Option>
                            </Select>
                        </Form.Row>

                        <Form.Row>
                            <InputField
                                id='days-per-week'
                                labelText='Working days per week'
                                placeholder='0'
                                min={1}
                                max={7}
                                onChange={(event) => dispatch({
                                    type: 'SET_TAKE_HOME_WORKING_DAYS',
                                    payload: event.target.value,
                                })}
                                value={query.workingDays}
                            />

                            <InputField
                                id='hours-per-day'
                                labelText='Working hours per day'
                                placeholder='0'
                                min={1}
                                max={24}
                                onChange={(event) => dispatch({
                                    type: 'SET_TAKE_HOME_WORKING_HOURS',
                                    payload: event.target.value,
                                })}
                                value={query.workingHours}
                            />
                        </Form.Row>
                            
                        <Form.Row>
                            <InputSwitch
                                id="no-ni-switch"
                                onChange={() => dispatch({
                                    type: 'SET_TAKE_HOME_NO_NI',
                                    payload: !query.noNI,
                                })}
                                checked={query.noNI}
                                modalHeading={definitions[5].frontmatter.title}
                                modalContent={<PageContent content={definitions[5].html} />}
                            >
                                No NI
                            </InputSwitch>

                            <InputSwitch
                                id="blind-switch"
                                onChange={() => dispatch({
                                    type: 'SET_TAKE_HOME_BLIND',
                                    payload: !query.blind,
                                })}
                                checked={query.blind}
                                modalHeading={definitions[0].frontmatter.title}
                                modalContent={<PageContent content={definitions[0].html} />}
                            >
                                Blind
                            </InputSwitch>

                            <InputSwitch
                                id="mca-switch"
                                onChange={() => dispatch({
                                    type: 'SET_TAKE_HOME_MCA',
                                    payload: !query.mca,
                                })}
                                checked={query.mca}
                                modalHeading={definitions[4].frontmatter.title}
                                modalContent={<PageContent content={definitions[4].html} />}
                            >
                                MCA
                            </InputSwitch>
                        </Form.Row>
                        
                        <Form.Row>
                            <InputSwitch
                                id="self-employed-switch"
                                onChange={() => dispatch({
                                    type: 'SET_TAKE_HOME_SELF_EMPLOYED',
                                    payload: !query.selfEmployed,
                                })}
                                checked={query.selfEmployed}
                                modalHeading={definitions[7].frontmatter.title}
                                modalContent={<PageContent content={definitions[7].html} />}
                            >
                                Self-employed
                            </InputSwitch>
                        </Form.Row>
                    </Form.Fieldset>
                </Form>
            </Section>
            
            <Section
                label='Calculation results'
                variants={['padded', 'secondary']}
            >
                <SummaryCard
                    id='salary'
                    heading='Your results'
                >
                    <Results
                        definitions={definitions}
                        bonusPay={bonusPay}
                        grossIncome={calcGrossIncome()}
                        personalAllowance={calcCorrectedPersonalAllowance}
                        taxableIncome={calcCorrectedTaxableIncome}
                        incomeTax={calcCorrectedIncomeTax}
                        nationalInsurance={calcNationalInsurance()}
                        studentLoan={calcStudentLoan()}
                        pension={calcPension()}
                        mcaTaxRebate={calcCorrectedMCATaxRebate}
                        takeHomePay={takeHomePay}
                    />

                    <div className='summary-card__controls'>
                        <ShareResults url={getShareLink(query, figures)} />
                    </div>
                </SummaryCard>
            </Section>
        </>
    );
};

SalaryCalculator.propTypes = {
    definitions: PropTypes.array.isRequired,
};

export default SalaryCalculator;